.MainHeader {
  .navbar-brand {
    img {
      margin-top: -10px;
      margin-bottom: -10px;
      width: 36px;
    }
  }
  .navbar-nav {
    .nav-item.dropdown.bg-opacity-10:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}
.dropdown-divider {
  border-top: 3px solid var(--bs-dropdown-divider-bg);
}
.unread-action-bg {
  background-color: #e7e7e7;
}

.action-item-row {
  cursor: pointer;
}
.action-item-row:hover {
  background-color: #f7f4f4;
}

.actions-dropdown-container{
  width: 380px;
  max-height: 400px;
  min-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
.actions-dropdown-container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.actions-dropdown-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
.actions-dropdown-container::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.actions-dropdown-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


