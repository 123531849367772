$primary : #019a20;
.stepper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $primary;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
  
  .title {
    margin-top: 5px;
    font-size: 16px;
  }
  
  .active .circle {
    color: white;
    background-color: $primary;
  }
  