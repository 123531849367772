$primary: #019a20;
$gray: #6c757d;

.nav-link {
  text-decoration: none;
  color: $gray;
  padding-bottom: 10px;

  &:hover,
  &.active,
  &:focus  {
    color: $primary;
    border-bottom: 3px solid $primary;
    border-radius: 1.5px;
  }
}
  .container{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: #cccecf solid 1.3px;
  }