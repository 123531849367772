.sticky-container {
  position: sticky;
  top: 55px; 
  background-color: #fff; 
  z-index: 1; 
}

.cell-width {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  font-size: 0.9rem;
}

.cell-heading-width {
  width: 400px;
  min-width: 300px;
  max-width: 300px;
  font-size: 0.9rem;
  text-align: left;
}

.cell-padding {
  padding: 0.5rem;
}

.phase-background {
  background-color: #cac7c7 !important;
}

.activity-background {
  background-color: #ebebeb !important;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

#costing-grid.table th:first-child,
#costing-grid.table td:first-child {
    position: -webkit-sticky; // 'position: sticky' is not supported by Safari on iOS < 13. Adding 'position: -webkit-sticky' to support Safari on iOS 7+
    position: sticky;
    left: 0;
    z-index: 1000;
    background-color: #fffffff9;
    box-shadow: 1px 0px #ccc;
}

.sticky-right {
    position: -webkit-sticky; // 'position: sticky' is not supported by Safari on iOS < 13. Adding 'position: -webkit-sticky' to support Safari on iOS 7+
    position: sticky;
    right: 0;
}

.sticky-left {
  position: -webkit-sticky; // 'position: sticky' is not supported by Safari on iOS < 13. Adding 'position: -webkit-sticky' to support Safari on iOS 7+
  position: sticky;
  left: 0;
}

.sticky-top {
  position: -webkit-sticky; // 'position: sticky' is not supported by Safari on iOS < 13. Adding 'position: -webkit-sticky' to support Safari on iOS 7+
  position: sticky;
  top: 0;
}
.top-10 {
  top: 10%;
}
.top-20 {
  top: 27%;
}

#costing-grid.table input[type=number]::-webkit-inner-spin-button,
#costing-grid.table input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance: none; // removes the spin icon on number input
  margin: 0; 
}

.table > :not(caption) > * > *{
  padding: 0.2rem;
}

.border-left-warning {
  border-left: 2px solid #FFC107 !important;
}

.border-right-warning {
  border-right: 2px solid #FFC107 !important;
}

.border-top-warning {
  border-top: 2px solid #FFC107 !important;
}

.border-bottom-warning {
  border-bottom: 2px solid #FFC107 !important;
}

.cursor-pointer {
  cursor: pointer;
}
