.OpportunitiesChart .approved-opps-chart-container {
  // height: 320px;
  width: 100%;
  overflow: visible;
}
@media (min-width: 576px) {
  .OpportunitiesChart .approved-opps-chart-container {
    // height: 320px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .OpportunitiesChart .approved-opps-chart-container {
    // height: 320px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .OpportunitiesChart .approved-opps-chart-container {
    // height: 320px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .OpportunitiesChart .approved-opps-chart-container {
    // height: 320px;
    width: 100%;
  }
}
