.project-code {
    padding: 5px 8px;
}

.tasks-list {
    height: 1030px;
    overflow-y: "auto",
}

.accruals-detail {
   height: 1030px;
} 