.all-action-items-list {
  min-height: 300px;
  max-height: 360px;
  overflow-y: auto;
  padding-left: 4px;
}

#assignment-dropdown-menu {
  max-height: 130px;
  overflow-y: auto;
}
